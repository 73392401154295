<template>
  <v-app>
    <landing-drawer
      :drawer.sync="drawer"
      @drawer-update="updateDrawer"
    ></landing-drawer>

    <v-app-bar app color="white" flat elevate-on-scroll dense>
      <v-toolbar-title flat>
        <v-btn
          link
          color="white"
          width="230px"
          :to="{ path: '/' }"
          class="hidden-sm-and-down white--text"
        >
          <v-img
            max-width="100"
            contain
            :src="require('@/assets/dekra-arbeit-gruppe-logo.png')"
            alt="Dekra"
          />
        </v-btn>
      </v-toolbar-title>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        color="grey"
        class="hidden-md-and-up"
      ></v-app-bar-nav-icon>

      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn text :to="{ name: 'mvs' }" color="primary">
          Master Vendor Solution
        </v-btn>
        <v-btn text :to="{ name: 'arbeit' }" color="primary">
          DEKRA Arbeit
        </v-btn>
        <v-btn text :to="{ name: 'signup' }" color="primary">
          Sign up
        </v-btn>
        <v-btn text :to="{ name: 'login' }" exact color="primary">
          Sign in
        </v-btn>
        <v-btn
          text
          href="https://www.dekra-arbeit.de/en/imprint.html"
          exact
          link
          target="_blank"
          color="primary"
        >
          Impressum
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <v-container class="fill-height pa-0 align-start" fluid>
        <transition name="router-fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </v-container>
    </v-main>
    <!--  <v-footer color="grey lighten-3" app>
      <span>&copy; {{ new Date().getFullYear() }}</span>
    </v-footer>-->
  </v-app>
</template>
<script>
export default {
  components: {
    LandingDrawer: () => import("@/components/core/LandingDrawer")
  },
  data() {
    return {
      search: "",
      drawer: false
    };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    updateDrawer(draw) {
      this.drawer = draw;
    }
  }
};
</script>
<style>
.width-230 {
  width: 230px;
}
.dekra-box-shadow {
  box-shadow: 0px 0px 10px -4px grey inset;
}
</style>
